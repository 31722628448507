/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/replaceall@0.1.6/replaceall.min.js
 * - /npm/jszip@3.10.1/dist/jszip.min.js
 * - /npm/jquery@3.7.1/dist/jquery.min.js
 * - /npm/jimp@0.16.1/browser/lib/jimp.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
